import * as React from 'react';
import Box from '@mui/material/Box';
import axios from "axios";
import ModalUsuarios from "../componentes/modalusuario";
import { useState, useEffect } from 'react';
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import { Button } from 'react-bootstrap';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ModalEditarUsuario from '../componentes/editarusuario';

export default function TablaUsuarios() {
    const URIUsuarios = "http://demo.edgarleal.es/web/obtener_usuarios.php"
    const [usuarios, setUsuario] = useState([])
    useEffect(() => {
        getUsuarios()
    },[])

    //procedimiento para mostrar los productos
    const getUsuarios = async () => {
        const res = await axios.get(URIUsuarios)
        setUsuario(res.data)
        console.log(res.data)
    }

    //procedimiento para eliminar un producto
    const deleteUsuario = async (idUsuarios) => {
        try{
            // Mostrar confirmación al usuario
            const confirmDelete = window.confirm('¿Estás seguro de que deseas eliminar este producto?');
            // Si el usuario cancela, no realizar la eliminación
            if (!confirmDelete) {
                return;
            }

            const res = await axios.post(`http://demo.edgarleal.es/web/eliminar_usuario.php?id=${idUsuarios}`);
            console.log(idUsuarios);
            getUsuarios();


        } catch (error) {
            console.log(error);
        }
    };

    return (

        <Container maxWidth="xl">
            <Box sx={{ marginLeft: "78%" }}>
                <ModalUsuarios />
            </Box>
            <div className="table-responsive">
                <Table striped bordered hover size="sm">
                <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Apellido</th>
                        <th scope="col">Rut</th>
                        <th scope="col">Empresa</th>
                        <th scope="col">Acciones</th>
                    </tr>
                </thead>
                    <tbody style={{ height: "100%" }}>
                        {usuarios.map(item => (
                            <tr key={item.idUsuarios}>
                                <td className='centrar'>{item.idUsuarios}</td>
                                <td className='centrar'>{item.nombre}</td>
                                <td className='centrar'>{item.apellido}</td>
                                <td className='centrar'>{item.rut}</td>
                                <td className='centrar'>{item.empresa}</td>
                                <td style={{ display: 'flex', gap: '10px' }}>
                                    <Button variant="danger" onClick={() => deleteUsuario(item.idUsuarios)}><DeleteForeverIcon /></Button>
                                    <ModalEditarUsuario usuarios={item} />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
            </div>
        </Container >
    );
}