import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import "./modal.css";
import axios from "axios";
import { useState, useEffect } from 'react';
import LogoRFID from "../imagenes/logorfid.png";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import {Button} from 'react-bootstrap';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import RoomIcon from '@mui/icons-material/Room';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


export default function ModalDetalleProducto({producto}) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [imagenes, setImagen] = useState([]);



    useEffect(() => {
        getImagenes()
    }, [])

    //procedimiento para mostrar las imagenes
    const getImagenes = async () => {
        const res = await axios.get(`http://demo.edgarleal.es/web/obtener_imagenes.php?product_id=${producto.idProductos}&order=desc`);
        setImagen(res.data);
        console.log(res.data)
        console.log(producto.idProductos)
        console.log(producto.descripcion)
    };

    return (
        <div>
            <Button variant="primary" onClick={handleOpen}><ManageSearchIcon /></Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Historial del producto
                    </Typography>

                    <Divider variant="middle" />

                    <Carousel interval={null}>
                        {imagenes.reverse().map((imagen) => (
                            <Carousel.Item key={imagen.id}>
                                <Card>      
                                    <Card.Body>
                                        <Card.Img src={imagen.ruta} alt={imagen.fechafoto} style={{ width: '50%', height: 'auto', top: "70%", float: "left" }} />
                                        <h2>{imagen.fechafoto}</h2>
                                        <Card.Title>
                                            Ubicacion: <a href={`https://www.google.com/maps/place/${imagen.latitud},${imagen.longitud}`} target="_blank"><RoomIcon sx={{ fontSize: 31 }} />Click para ver</a>
                                        </Card.Title>
                                        <Card.Text>
                                            Nombre: {producto.descripcion}
                                            <br></br>
                                            Adicional: {producto.adicional}
                                            <br></br>
                                            Tag: {producto.epc}
                                            <br></br>
                                            Cod. de barra: {producto.codigobarra}
                                            <br></br>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </Box>
            </Modal>
        </div>
    );
}