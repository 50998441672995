import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import "./modal.css";
import LogoRFID from "../imagenes/logorfid.png";
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { validate } from 'rut.js';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function BasicModal() {

    const URI = "http://demo.edgarleal.es/web/guardar_usuario.php"
    const [nombre, setNombre] = useState('')
    const [apellido, setApellido] = useState('')
    const [rut, setRut] = useState('')
    const [clave, setClave] = useState('')
    const navigate = useNavigate()
    const [rutError, setRutError] = useState('');

    //procedimiento guardar
    const Guardar = async (e) => {
        e.preventDefault();

        // Verificar si los campos requeridos están vacíos
        if (!nombre || !apellido || !rut || !clave) {
            // Mostrar mensaje de error o realizar alguna acción
            alert('Por favor, complete todos los campos');
            return;

        }

        // Validar el campo rut
        if (!validate(rut)) {
            // El RUT no es válido, mostrar mensaje de error o realizar alguna acción
            setRutError('RUT no válido');
            return;
        }
        try {
            // Realizar la solicitud POST
            await axios.post(URI, { nombre: nombre, apellido: apellido, rut: rut, clave: clave })

            // Mostrar mensaje de éxito
            alert('Usuario guardado');

            // Cerrar el modal (opcional)
            handleClose();

            // Opcional: Reiniciar los valores del formulario
            setNombre('');
            setApellido('');
            setRut('');
            setClave('');

            // Navegar a la página de usuarios 
            navigate('/Usuarios#');
            navigate('/Usuarios#')
        } catch (error) {
            // Manejar errores de la solicitud POST, si es necesario
            console.error(error);
        }
    }

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Button variant="contained" style={{ marginLeft: "57%", height: 50 }} onClick={handleOpen}>Agregar Usuario</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Agregar Nuevo Usuario
                    </Typography>

                    <Divider variant="middle" />
                    <form onSubmit={Guardar}>
                        <div className='input-container'>
                            <div>
                                <div className='input-container'>
                                    <TextField 
                                    className='input-text' 
                                    id="LabelModalRut" 
                                    label="Rut" 
                                    variant="outlined"
                                        onChange={(e) => setRut(e.target.value)}
                                        error={rutError !== ''}
                                        helperText={rutError}
                                    />
                                </div>
                                <div className='input-container'>
                                    <TextField className='input-text'
                                        id="LabelModalNombre" 
                                        label="Nombre"
                                        variant="outlined"
                                        onChange={(e) => setNombre(e.target.value)} />

                                    <TextField className='input-text'
                                        id="LabelModalApellido" 
                                        label="Apellido"
                                        variant="outlined"
                                        onChange={(e) => setApellido(e.target.value)} />

                                </div>
                                <div className='input-container'>
                                    <TextField className='input-text'
                                        id="LabelModalContrasena"
                                        label="Contraseña"
                                        type='password'
                                        variant="outlined"
                                        onChange={(e) => setClave(e.target.value)} />
                                </div>
                                <div className="input-container">
                                    <button type='submit' className='btn btn-primary'>Guardar</button>
                                    <Button className="boton-container" variant="outlined">Cancelar</Button>
                                </div>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div>
                                <img className='tamano-imagen' src={LogoRFID} />
                            </div>
                        </div>
                    </form>
                </Box>
            </Modal>
        </div>
    );
}