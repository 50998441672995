import * as React from 'react';
import Box from '@mui/material/Box';
import axios from "axios";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import ModalProductos from "../componentes/modalproducto";
import { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import RoomIcon from '@mui/icons-material/Room';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ModalDetalleProducto from '../componentes/modaldetalleproducto';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


export default function TablaProductos() {
    const URIProductos = "http://demo.edgarleal.es/web/obtener_productos.php"
    const [productos, setProducto] = useState([])

    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    useEffect( ()=>{
        getProductos()
    },[])

    //procedimiento para mostrar los productos
    const getProductos = async () =>{
        const res = await axios.get(URIProductos)
        setProducto(res.data)
        console.log(res.data)
    }

    //procedimiento para eliminar un producto
    const deleteProducto = async (idProductos) => {
        try {
            // Mostrar confirmación al usuario
            const confirmDelete = window.confirm('¿Estás seguro de que deseas eliminar este producto?');

            if (!confirmDelete) {
                return; 
            }

            const res = await axios.post(`http://demo.edgarleal.es/web/eliminar_producto.php?id=${idProductos}`);
            console.log(idProductos);
            getProductos();

        } catch (error) {
            console.log(error);
        }
    }

    // Abrir imagen en modal
    const openImageModal = (image) => {
        setSelectedImage(image);
        setShowModal(true);
    };

    // Cerrar modal
    const closeImageModal = () => {
        setShowModal(false);
        setSelectedImage('');
    };

    

    return (
        <body>
        <Container maxWidth="xl">
            <div className="table-responsive">
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th scope="col">Descripcion</th>
                            <th scope="col">Estado</th>
                            <th scope="col">Epc</th>
                            <th scope="col">Codigo de barra</th>
                            <th scope="col">Fecha de registro</th>
                            <th scope="col">Ubicacion</th>
                            <th scope="col">Foto</th>
                            <th scope="col">Acciones</th>
                        </tr>
                    </thead>
                    <tbody style={{ height: "100%" }}>
                        {productos.map((item) => (
                            <tr key={item.idProductos}>
                                <td className='centrar'>{item.descripcion}</td>
                                <td className='centrar'>{item.pestado}</td>
                                <td className='centrar'>{item.epc}</td>
                                <td className='centrar'>{item.codigobarra}</td>
                                <td className='centrar'>{item.fechaproducto}</td>   
                                <td className='centrar'><a href={`https://www.google.com/maps/place/${item.platitud},${item.plongitud}`} target="_blank">
                                    <RoomIcon sx={{ fontSize: 31 }} /> <p >  ver</p>
                                </a></td>
                                <td>
                                    <div onClick={() => openImageModal(item.foto)}>
                                        <img src={item.foto} alt={item.foto} style={{ width: 70, height: 70}} />
                                    </div>    
                                </td>
                                <td style={{ display: 'flex', gap: '10px' }}>
                                    <Button variant="danger" onClick={() => deleteProducto(item.idProductos)}><DeleteForeverIcon/></Button>
                                    <ModalProductos producto={item} />
                                    <ModalDetalleProducto producto={item} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
                <Modal show={showModal} onHide={closeImageModal} sx={style} >
                <Modal.Header closeButton>
                    
                    <Modal.Title>Imagen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <img src={selectedImage} alt="Imagen" style={{ width: '100%', height: 'auto', top: "70%" }} />
                </Modal.Body>
            </Modal>
        </Container >
        </body>
    );
}