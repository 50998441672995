import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LogoRFID from '../imagenes/Logo animado.gif';
import {
    MDBContainer,
    MDBInput,
    MDBCheckbox,
    MDBBtn,
    MDBIcon
}
    from 'mdb-react-ui-kit';
import { Height } from '@mui/icons-material';

const Login = ({ setIsLoggedIn }) => {
    const [rut, setRut] = useState('');
    const [clave, setClave] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate()

    const handleRutChange = (e) => {
        setRut(e.target.value);
    };

    const handleClaveChange = (e) => {
        setClave(e.target.value);
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://demo.edgarleal.es/web/login.php', { rut, clave });

            if (response.status === 200) {
                setIsLoggedIn(true);
                setError('');
                navigate('/dashboard');
            } else {
                setError('Credenciales inválidas');
            }
        } catch (error) {
            setError('Error en la solicitud HTTP');
        }
    };


    return (
        <MDBContainer className="p-3 my-5 d-flex flex-column w-50">
            <div className="text-center mb-4" style={{ paddingBottom: "20px" }}>
                <img src={LogoRFID}
                    style={{ width: '70%' }} alt="logo" />
            </div>

            <MDBInput wrapperClass='mb-4' label='Rut' id='form1' value={rut} onChange={handleRutChange} />
            <MDBInput wrapperClass='mb-4' label='Contraseña' id='form2' type='password' value={clave} onChange={handleClaveChange} />

            <div className="d-flex justify-content-between mx-3 mb-4">
            </div>

            <MDBBtn className="mb-4" onClick={handleLogin}>Ingresar</MDBBtn>

            <div className="text-center">

                <div className='d-flex justify-content-between mx-auto' style={{ width: '40%' }}>
                    <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#1266f1' }}>
                        <MDBIcon fab icon='facebook-f' size="sm" />
                    </MDBBtn>

                    <MDBBtn tag='a' color='none' className='m-1' style={{ color: '#1266f1' }}>
                        <MDBIcon fab icon='twitter' size="sm" />
                    </MDBBtn>

                </div>
            </div>

        </MDBContainer>
    );
};

export default Login;